<template>
  <div>
    <el-form>
      <el-form-item label="Строка в формате wkt">
        <el-input type="text" v-model="stringWKT"/>
      </el-form-item>
      <el-form-item label="Система координат строки">
        <coordinate-system
          :v-model="CS"
          :options="userCSs"
          @change-cs="changeCS"
        ></coordinate-system>
      </el-form-item>
    </el-form>
    <el-button @click="sendImport">Импортировать</el-button>
  </div>
</template>

<script>
  import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

  export default {
    name: 'stringWKTForm',
    components: {CoordinateSystem},
    props: {
      userCSs: Array
    },
    data: function () {
      return {
        stringWKT: null,
        CS: null
      }
    },
    methods: {
      changeCS(newVal) {
        this.CS = newVal
      },
      sendImport() {
        this.$emit('import-wkt-string', this.stringWKT, this.CS)
      }
    }
  }
</script>

<style scoped>

</style>
